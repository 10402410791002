import React from 'react'
import { Heading, Text, Link } from '../ui'
import PostThumbnailRemote from './PostThumbnailRemote'
import PostExcerpt from './PostExcerpt'

const PostPreview = ({
  uri, title, excerpt, meta, featuredImage,
}) => (
  <article>
    <Link to={uri}>
      <PostThumbnailRemote
        alt={title}
        {...featuredImage.node}
        {...featuredImage.node.mediaDetails}
        height={170}
      />
      <Heading
        mt={3}
        as="h2"
        fontSize={3}
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <Text
        mt={1}
        variant="description"
      >
        {meta}
      </Text>
      <PostExcerpt my={3}>
        {excerpt ? excerpt.replace(/<[^>]+>/g, '') : ''}
      </PostExcerpt>
    </Link>
  </article>
)

export default PostPreview
